<template>
  <div id="app">
    <!-- <Nav></Nav> -->
    <router-link to="/"></router-link>
    <!-- <router-view v-if="isRouterAlive" /> -->


    <router-view />
    <!-- <Bottom></Bottom> -->
  </div>
</template>
<script>
// import Nav from "./components/nav/nav.vue";
// import Bottom from "./components/bottom/bottom.vue";
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  // components: { Nav,Bottom },
  mounted() {
    // let script = document.createElement('script');
    // script.src = 'https://tb.53kf.com/code/code/1937a5940ba064a938c82424b0f4e5057/1';
    // document.body.appendChild(script);

    // if(this._isMobile()){
    //   location.href = process.env.VUE_APP_URL
    // }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    // 判断是否是手机端，如果是，返回true
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  },
};
</script>
<style  src="../src/assets/css/common.css"  >
</style>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  cursor: pointer;
  color: #505E77;
  text-decoration: none;
}
ul {

list-style-type: none;

}
#app{
  background-color: #F7F8FB;
}
.scroll::-webkit-scrollbar { width:6px;  height:1px; }  /*高宽分别对应横竖滚动条的尺寸*/
/*滚动条里面小方块*/
.scroll::-webkit-scrollbar-thumb {border-radius: 4px; box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1); background: #c6cfdb; }
/*滚动条里面轨道*/
.scroll::-webkit-scrollbar-track { /*box-shadow:inset 0 0 5px rgba(0, 0, 0, 0.2);*/ background: #ededed;}
  
.ellipsis{overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }
.ellipsis2{ overflow : hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp:2; -webkit-box-orient: vertical;}
.clearfix:after{content:""; clear:both; display:block;}

.ie_tips_wrapper {position: fixed; left:0; right:0; top:0; bottom:0; background:#ccc; background:rgba(0,0,0,.7); z-index:999999;}
.ie_tips_box {width:500px; background:#fff; height:360px; position:absolute; left:50%; top:50%; margin-left:-300px; margin-top:-200px; padding:30px;}
.ie_tips_item {width:140px; float:left; text-align:center;}



/*字体颜色*/
.f_blue { color: #1776FF;}
.f_green {color: #5fbf31;}
.f_grey {color: #a5a5a5;}
.f_orange {color: #ff8f34;}
.f_red {color:#FF3939;}

/*字体大小*/
.f_12 {font-size:12px;} .f_13 {font-size:13px;} .f_14 {font-size:14px;} .f_15 {font-size:15px;} .f_16 {font-size:16px;color: #333333;} .f_18 {font-size:18px;color: #333333;} .f_20 {font-size:20px;color: #fff;}.f_22 {font-size:22px;} .f_24 {font-size:24px;} .f_26 {font-size:26px;} .f_28 {font-size:28px;} 
.mt10 {margin-top:10px;}.mt20 {margin-top:20px;color: #333333;}.mt30 {margin-top:30px;}.mt40 {margin-top:40px;color: #333333;}.mt50 {margin-top:50px;}.mt60 {margin-top:60px;color: #333333;}.mt80 {margin-top:80px;}
.ml10 {margin-left:10px;} .ml20 {margin-left:20px;} .ml30 {margin-left:30px;}.ml40 {margin-left:40px;}.ml0 {color: #505E77;}.ml1 {color: #333333;}.ml2{color: #fff;}.ml3 {color: #fff;}
.mt200{color: #333333;}
.txt_bold {font-weight:bold;}
.txtInfo { color:#999; line-height:28px; text-indent:2em}
.f_i9 {font-size:22px;color:#fff;font-size: 22px;}
/*图片, 图标字体垂直居中*/
.txt_align {text-align: center;}
.txt_align_h1{color: #674C4F;}
.txt_right { text-align: right;}
.img_middle {vertical-align:middle;}
.fl {
  float: left;
}
.fr{
  float: right;
}
.c_btn { background:#ffa41c; color:#fff; line-height:34px;  display:inline-block; padding:0 30px;  text-align:center; }
.c_btn:hover {color:#fff;}
.c_btn.mini { line-height:22px; font-size:12px;}
.c_btn.medium {line-height:32px; }
.c_btn.big { line-height:42px; font-size:16px;}
.c_btn.round {border-radius:20px; padding:0 20px;}
/* .c_btn.primary {background:#1776FF; background: linear-gradient(135deg, #6BB8FF 0%, #0680F1 45%, #0680F1 100%);} */
.c_btn.primary {/*background:#1776FF;*/ background: #1776FF}
/*.c_btn.primary:hover {background:#136af9;} */
.c_btn.primary1 {background: linear-gradient(135deg, #1776FF 0%, #1776FF 45%, #1776FF 100%);}
.c_btn.success {background:#7ac23c;}
.c_btn.white {background:#fff; color: #1776FF;}
.c_btn.black {background:#fff; color: #000;}
.c_btn.grey {background:#ccc; color: #fff;}
.c_btn.white.border {background:none; color: #fff; border:1px solid #fff;}
.c_btn img {vertical-align:middle;}
.content{
  min-width: 1240px;
  margin: 0 auto;
}
.main_title{
  color: #333333;
  font-size: 36px;
  font-weight: bold;
  padding: 80px 0 14px;
  text-align: center;
}
.main_desc{
  color: #333333;
  font-size: 20px;
  margin-bottom: 60px;
  text-align: center;
}
.lineOne{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
