<template>
  <div >
    <div class="header " :class="{headerChange:!state}">
		<div class="head clearfix">
			<h1 class="logo"  :class="{eng:htmlMark == 'eng'}">
			<a href="" :class="{actie:!state}">Hotmobi</a>
		</h1>
			<div class="nav" :class="{eng:htmlMark == 'eng'}">

				<ul>
					<li class="redBg">
						<a  @click="tabsColor('#page_2')" class="page_click" :class="{'navbar-a-color':!state}">{{ languageText[htmlMark].header.headerText1 }}</a>

					</li>
					<li class="redBg">
						<a  @click="tabsColor('#page_3')" class="page_click" :class="{'navbar-a-color':!state}">{{ languageText[htmlMark].header.headerText2 }}</a>

					</li>
					<li class="redBg">
						<a  @click="tabsColor('#page33')" class="page_click" :class="{'navbar-a-color':!state}">{{ languageText[htmlMark].header.headerText3 }}</a>

					</li>
					<li class="redBg">
						<a  @click="tabsColor('#adlogoin')" class="page_click" :class="{'navbar-a-color':!state}">{{ languageText[htmlMark].header.headerText4 }}</a>

					</li>

					<li class="redBg">
						<a  @click="tabsColor('#page_4')" class="page_click" :class="{'navbar-a-color':!state}">{{ languageText[htmlMark].header.headerText5 }}</a>

					</li>

					<li class="redBg">
						<a  @click="tabsColor('#page_5')" class="page_click" :class="{'navbar-a-color':!state}">{{ languageText[htmlMark].header.headerText6 }}</a>

					</li>
					<li style="padding: 0 1rem;position: relative;" class="jjj">
						<span class="loginS" :class="{'navbar-a-color':!state,eng:htmlMark == 'eng'}">{{ languageText[htmlMark].header.headerText7 }}
							<div class="lay"  style="display: none;display: none;width: 140px;height: 100px;position: absolute;top:44px;left: 4px;">
								<div class="layer">
									<span class="layerT" @click="toOther('https://dsp.adunite.com/')" :class="{'navbar-a-color':!state}">{{ languageText[htmlMark].header.headerText7Obj.text1 }}</span>
									<span class="layerB" @click="toOther('https://adx.adunite.com/')" :class="{'navbar-a-color':!state}">{{ languageText[htmlMark].header.headerText7Obj.text2 }}</span>
								</div>
							</div>
						</span>
						<span :class="{'navbar-a-color':!state}" style="font-size: 0.9rem;color: #333;">/</span>
						<span class="zhuce" :class="{'navbar-a-color':!state,eng:htmlMark == 'eng'}">{{ languageText[htmlMark].header.headerText8 }}
							<div class="lay2"  style="display: none;width: 100px;height: 100px;position: absolute;top:44px;left: 4px;">
							<div class="layer2">
								<span class="layerT2" @click="toOther('https://dsp.adunite.com/')" :class="{'navbar-a-color':!state}">{{ languageText[htmlMark].header.headerText8Obj.text1 }}</span>
								<span class="layerB2" @click="toOther('https://adx.adunite.com/')" :class="{'navbar-a-color':!state}">{{ languageText[htmlMark].header.headerText8Obj.text2 }}</span>
							</div>
							
							</div>
						</span>
					</li>
					<li class="encn pulldown" style="margin-top: 25px; margin-left: 1.25rem; border-color: rgb(255, 255, 255);" :class="{bgs:state,eng:htmlMark == 'eng'}"   @click="selectLang">
						<span class="chinese" :class="{'navbar-a-color':!state}">{{ languageText[htmlMark].header.headerText9Obj.text1 }}
								<i></i>
							</span>
						<ul id="language " :class="{show:languageState}">
							<li>
								<a class="english" :class="{'navbar-a-color':!state}" @click="changeLanguage(languageText[htmlMark].header.headerText9Obj.text2)">{{ languageText[htmlMark].header.headerText9Obj.text2 }}</a>
							</li>
						</ul>
					</li>
				</ul>

			</div>

		</div>
	</div>
    <section class="video page_1" id="page_1">
      <div class="bg">
        <video id="bgvideo" muted="true" autoplay="" loop="" poster="../../assets/video/video-banner.png" class="embed-responsive-item">
          <source type="video/mp4" src="../../assets/video/video.mp4">			
          <source type="video/mp4" src="../../assets/video/jjjj.mp4">			
          <source type="video/ogg" src="../../assets/video/jjjj.ogg">
        
        </video>
      </div>
      <div class="filter"></div>
      <el-carousel indicator-position="none" class="elCarousel">
        <el-carousel-item v-for="item in languageText[htmlMark].page_1.bannerList" :key="item">
          <div class="t5" :class="{eng:htmlMark == 'eng'}">{{ languageText[htmlMark].page_1.bannerText }}</div>
          <div class="t1" :class="{eng:htmlMark == 'eng'}">{{ item }}</div>
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="container swiper-container swiper-container-horizontal">
        <div class="swiper-wrapper" style="transform: translate3d(-2984px, 0px, 0px); transition-duration: 0ms;">
          <div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" style="width: 1492px;">
            <div class="t5">我们让</div>
            <div class="t1">广告变得更有效果</div>
          </div>
          <div class="swiper-slide swiper-slide-prev" style="margin-top: 0px; margin-left: auto; width: 1492px;" data-swiper-slide-index="0">
            <div class="t5">我们让</div>
            <div class="t1">广告变得更有意义</div>	
          </div>
          <div class="swiper-slide swiper-slide-active" data-swiper-slide-index="1" style="width: 1492px;">
            <div class="t5">我们让</div>
            <div class="t1">广告变得更有内容</div>
          </div>
        </div>
      </div> -->
    </section>
    <div class="page_2" id="page_2">
			<div class="info_plate">
				<span class="info_Title">{{ languageText[htmlMark].page_2.title }}</span>
				<span class="info_Intr">{{ languageText[htmlMark].page_2.desc }}</span>
				<ul>
					<li>
						<div class="info_box">
							<span class="info_ico"></span>

							<div class="info_con">
								{{ languageText[htmlMark].page_2.text1 }}
							</div>
						</div>
					</li>
					<li>
						<div class="info_box">
							<span class="info_ico info_ico2"></span>

							<div class="info_con">
								{{ languageText[htmlMark].page_2.text2 }}
							</div>
						</div>
					</li>
					<li>
						<div class="info_box">
							<span class="info_ico info_ico3"></span>

							<div class="info_con">
								{{ languageText[htmlMark].page_2.text3 }}
							</div>
						</div>
					</li>
					<li>
						<div class="info_box">
							<span class="info_ico info_ico4"></span>

							<div class="info_con">
								{{ languageText[htmlMark].page_2.text4 }}
							</div>
						</div>
					</li>
					<li>
						<div class="info_box">
							<span class="info_ico info_ico5"></span>

							<div class="info_con">
								{{ languageText[htmlMark].page_2.text5 }}
							</div>
						</div>
					</li>
					<li>
						<div class="info_box">
							<span class="info_ico info_ico6"></span>

							<div class="info_con">{{ languageText[htmlMark].page_2.text6 }}
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
    <div class="page_3" id="page_3" >
			<div class="container" style="position: relative;">
				<ul>
					<li>
						<span class="page3Pic"></span>
						<div class="page3Tnav">
							<span class="page3ti">{{ languageText[htmlMark].page_3.obj1.title }}</span>
							<span class="page3in">{{ languageText[htmlMark].page_3.obj1.model }}</span>
							<span class="page3tn">{{ languageText[htmlMark].page_3.obj1.desc }}</span>
						</div>
					</li>
					<li style="position: relative;">
						<div class="page3Enav">
							<span class="page3Eti">{{ languageText[htmlMark].page_3.obj2.title }}</span>
							<span class="page3Ein">{{ languageText[htmlMark].page_3.obj2.model }}</span>
							<span class="page3Etn">{{ languageText[htmlMark].page_3.obj2.desc }}</span>
						</div>
						<span class="page3EPic"></span>
					</li>
				</ul>
				
			</div>
		</div>
    <div class="page33"  id="page33">
			<div class="container">
				<span class="page33T">{{ languageText[htmlMark].page33.title }}</span>
				<div class="page33N" :class="{eng:htmlMark == 'eng'}">
					<span>{{ languageText[htmlMark].page33.desc }}</span>
				</div>
			</div>
		</div>
    <div class="Nopage">
			<div class="container">
				<ul class="noPageU">
					<li>
						<span class="NopageF" v-html="languageText[htmlMark].Nopage.text1"></span>
						<span class="NopageP"></span>
					</li>
					<li>
						<span class="NopageF">{{ languageText[htmlMark].Nopage.text2 }}</span>
						<span class="NopageP3"></span>
					</li>
					<li>
						<span class="NopageF">{{ languageText[htmlMark].Nopage.text3 }}</span>
						<span class="NopageP5"></span>
					</li>
					<li class="Nopage2"> 
						<span class="NopageP2"></span>
						<span class="NopageF2">{{ languageText[htmlMark].Nopage.text4 }}</span>
					</li>
					<li class="Nopage4">
						<span class="NopageP4"></span>
						<span class="NopageF2">{{ languageText[htmlMark].Nopage.text5 }}</span>
					</li>
				</ul>
			</div>
		</div>
    <div class="adlogoin" id="adlogoin">
			<div class="container">
				<span class="adloinT">{{ languageText[htmlMark].adlogoin.title }}</span>
				<span class="adloinF" v-show="zcNum == 2">
          <span style="display: block;">{{ languageText[htmlMark].adlogoin.descObj2.text1 }}</span>
          <span>{{ languageText[htmlMark].adlogoin.descObj2.text2 }}</span>
        </span>
				<span class="adloinF2" v-show="zcNum == 1">
          <span style="display: block;">{{ languageText[htmlMark].adlogoin.descObj1.text1 }}</span>
          <span>{{ languageText[htmlMark].adlogoin.descObj1.text2 }}</span>
        </span>
				
				<div class="yyy">
					<div class="adloge" @mouseover="zc(1)" @click="toOther('https://dsp.adunite.com/')" >{{ languageText[htmlMark].adlogoin.btn1 }}</div>
          <div class="adloge2" @mouseover="zc(2)" @click="toOther('https://adx.adunite.com/')">{{ languageText[htmlMark].adlogoin.btn2 }}</div>
				</div>
			</div>
		</div>
    <div class="page_4" id="page_4">
			<div class="strategic">
				<div class="strategic_tit">{{ languageText[htmlMark].page_4.title }}</div>
				<div class="mr_frbox">

					<div class="strategic_list mr_frUl">
						<div class="tempWrap" style="overflow:hidden; position:relative; width:1210px">
              <ul :style="'left: '+px+'px;'">

						<li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-13.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-14.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-15.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-16.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-17.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-18.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-19.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-20.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-01.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-02.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-03.png">
							
							</span></li><li style="float: left; width: 111px;">
								
								<span class="strat_img">
								<img src="../../assets/images/logo-04.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-05.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-06.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-07.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-08.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-09.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-10.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-11.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-12.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-13.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-14.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-15.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-16.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-17.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-18.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-19.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-20.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-01.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-02.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-03.png">
							
							</span></li><li style="float: left; width: 111px;">
								
								<span class="strat_img">
								<img src="../../assets/images/logo-04.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-05.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-06.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-07.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-08.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-09.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-10.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-11.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-12.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-13.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-14.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-15.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-16.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-17.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-18.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-19.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-20.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-01.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-02.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-03.png">
							
							</span></li><li style="float: left; width: 111px;">
								
								<span class="strat_img">
								<img src="../../assets/images/logo-04.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-05.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-06.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-07.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-08.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-09.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-10.png">
							</span>
							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-11.png">
							</span>

							</li><li style="float: left; width: 111px;">
								<span class="strat_img">
								<img src="../../assets/images/logo-12.png">
							</span>
							</li></ul></div>
					</div>

				</div>
			</div>
		</div>
    <div class="page_5" id="page_5">
			<div class="container">
			<form class="contact_us" id="registerForm" method="post" enctype="text/plain">
				
				<span class="page5Title">{{ languageText[htmlMark].page_5.title }}</span>
				<div class="chooseAd" :class="{eng:htmlMark == 'eng'}">
					<input type="hidden" id="switchId" name="switch" value="1">
					<input type="hidden" id="languageSend" name="languageSend" value="1">
					<input type="hidden" id="paltform" name="paltform" value="1">
					<ul>
						<li class="clickpoint" @click="toContentNum(1)">
							<span class="clickp" :class="{redpoint:contentNum == 1,borpoint :contentNum == 2}" id="switchPub"></span><span class="medioEn">{{ languageText[htmlMark].page_5.type1 }}</span>
						</li>
						<li class="clickpoint" style="text-align: center" @click="toContentNum(2)">
							<span class="clickp" :class="{redpoint:contentNum == 2,borpoint :contentNum == 1}" id="switchAdv"></span><span class="medioEn">{{ languageText[htmlMark].page_5.type2 }}</span>
						</li>
					</ul>
				</div>

				<div class="inputInfo">
					<div class="inputLeft">
						<div class="inputbox">
						<input type="text" name="name" :placeholder="languageText[htmlMark].page_5.form1" id="IdName" v-model="from.name">
						<div class="textpoint clearfix">
			        		<span class="error-point">姓名必填</span>
			        	</div>
			        	</div>
						<div class="inputbox">
						<input type="text" name="company" :placeholder="languageText[htmlMark].page_5.form2" id="IdCompany" v-model="from.company">
						<div class="textpoint clearfix">
			        		<span class="error-point">公司名称必填</span>
			        	</div>
			        	</div>
						<div class="inputbox">
						<input type="text" name="phone" id="IdPhone" v-model="from.phone" :placeholder="languageText[htmlMark].page_5.form3">
						<div class="textpoint clearfix">
			        		<span class="error-point">电话必填</span>
			        	</div>
			        	</div>
						<div class="inputbox">
						<input type="text" name="email" id="IdEmail" v-model="from.email" :placeholder="languageText[htmlMark].page_5.form4">
						<div class="textpoint clearfix">
			        		<span class="error-point">邮箱必填</span>
			        	</div>
			        	</div>
						
					</div>
					<div class="inputRight">
						<input type="text" name="subject" id="IdSubject" :placeholder="languageText[htmlMark].page_5.form5" v-model="from.subject">
						<textarea class="inputNav" name="message" id="IdMessage" :placeholder="languageText[htmlMark].page_5.form6" rows="" cols="" v-model="from.message"></textarea>
					</div>
				</div>
				
				<div class="sendEmail">
					<span class="EmailB" id="btnSend" @click="btnSend">{{ languageText[htmlMark].page_5.btn }}</span>
				</div>
			</form></div>
			
		</div>
    <div class="footer">
			<div class="foot_box">
				<div class="foot_center" :class="{eng:htmlMark == 'eng'}">
					<div class="footLeft">
						<span class="footTitle">Hotmobi</span>
						<!-- <span class="lineI"></span> -->
						<span><a href="#adlogoin">{{ languageText[htmlMark].footer.text1 }}</a></span>
						<span><a href="#page33">{{ languageText[htmlMark].footer.text2 }}</a></span>
						<span><a href="#page_5">{{ languageText[htmlMark].footer.text3 }}</a></span>
					</div>
					<div class="foot-address" :class="{disNone:htmlMark == 'eng'}">
						<span class="footTitle">关注我们</span>
						<!-- <span class="lineI"></span> -->
						<p class="hot-map-new">上海市普陀区中江路879弄（天地软件园）3幢303</p>
						<p>电话：400-8160-569</p>
					</div>
					
					<div class="footRight">
						<span class="footTitle">{{ languageText[htmlMark].footer.text4 }}</span>
						<!-- <span class="lineI"></span> -->
						<span class="ewm"></span>
					</div>
				</div>
				<div class="foot_copy">
					{{ languageText[htmlMark].footer.copy }}
				</div>
			</div>
		</div>
  </div>
</template>

<script>
// import Banner from "@/components/banner/banner.vue";
// import custom from "@/components/custom/custom.vue";
// import callPhone from "@/components/callPhone/index.vue";
export default {
  data() {
    return {
      bannerList:['广告变得更有效果','广告变得更有意义','广告变得更有内容'],
	  htmlMark:'cn',
	  languageText:{
		cn:{
			header:{
				headerText1:'平台优势',
				headerText2:'合作模式',
				headerText3:'关于Hotmobi',
				headerText4:'广告合作',
				headerText5:'合作伙伴',
				headerText6:'联系我们',
				headerText7:'登录',
				headerText7Obj:{
					text1:'广告主',
					text2:'媒体'
				},
				headerText8:'注册',
				headerText8Obj:{
					text1:'广告主',
					text2:'媒体'
				},
				headerText9Obj:{
					text1:'中文',
					text2:'English'
				},
			},
			page_1:{
				bannerText:'我们让',
				bannerList:['广告变得更有效果','广告变得更有意义','广告变得更有内容'],
			},
			page_2:{
				title:'海外移动效果营销平台',
				desc:'海量海外流量资源聚合能力，聚集三十大平台，12亿用户，22000家广告主，2.5亿日活，覆盖100个国家及地区',
				text1:'能够通过精准数据参数进行精准定向，帮助您轻松实现推广目标。',
				text2:'自助服务平台依靠强有力的技术支撑，能够满足您所有的数据追踪需求，实时监测您的转化和广告投放结果。',
				text3:'多种广告展现形式如：横幅，视频，文字等，能够触达全球的流量，让移动广告投放无死角。',
				text4:'通过品牌的持续曝光提升，以及吸引有购买意向的高质量用户，可为您带来更多的收入。',
				text5:'根据您的广告投放kpi设置精确的消费投放计划和每日预算。',
				text6:'我们拥有先进的自动过滤技术，拒绝bot流量，恶意点击，恶意软件和病毒。',
			},
			page_3:{
				obj1:{
					title:'数据对接',
					model:'',
					desc:'数据对接：通过talking data、热云、友盟等当下专业第三方监测平台以及双方服务器数据回传，来进行良性的数据化效果对接，保证投放效果的追踪和优化。'
				},
				obj2:{
					title:'结算方式',
					model:'',
					desc:'分两种模式：1.程序化广告，CPC/CPM实时竞价。2.通过CPA可以实现注册结算、激活结算、其他深度结算等良好的投放结算方式'
				},
			},
			page33:{
				title:'关于Hotmobi',
				desc:'Hotmobi隶属于上海旺翔数字科技股份有限公司，并作为海外移动效果营销平台，致力于利用移动广告有效地改变与终端用户的交流方式，通过联盟网络、视频网络、原生网络、应用网络、程序化网络、社交网络、搜索引擎等不同渠道的移动营销策略和平台特性实现投放收益。'
			},
			Nopage:{
				text1:'Hotmobi凭借全球多样化、规模化的渠道，能够帮助开发者在最短的时间内获取规模化的海外用户，使产品快速占据海外目标市场。<br/><br/>同时，基于Hotmobi多年来在中国大陆积累的资源和技术经验，采用DSP ADX的组合策略，协助海外品牌扩展他们在中国市场的业务。',
				text2:'先进的智能场景匹配引擎技术，细分用户生活，工作，娱乐各种场景，精准匹配广告主广告，个性化营销。',
				text3:'国内领先的大数据运算引擎技术，处理海量大数据广告需求，并建立投放模型，精准化投放。',
				text4:'海量海外流量资源聚合能力，聚集三十大平台，12亿用户，22000家广告主，2.5亿日活，覆盖100个国家及地区。',
				text5:'深度的广告SDK定制引擎技术，通过底层大数据运算，匹配适合广告主的广告形式，灵活切换广告形式，提升广告营销效果。',
			},
			adlogoin:{
				title:'从量变到质变 盈利更大化',
				descObj1:{
					text1:'避免无效点击，提高投资回报率。',
					text2:'Hotmobi创新自助平台，更大限度利用流量， 以确保超佳的广告投放效果。'
				},
				descObj2:{
					text1:'你有优质流量吗？',
					text2:'加入我们，实现流量变现'
				},
				btn1:"广告主注册",
				btn2:'媒体注册'
			},
			page_4:{
				title:'合作伙伴'
			},
			page_5:{
				title:'联系我们',
				type1:'媒体资源',
				type2:'广告发布',
				form1:'姓名',
				form2:'公司名称',
				form3:'电话',
				form4:'邮箱',
				form5:'主题',
				form6:'输入内容',
				btn:'发送'
			},
			footer:{
				copy:'© Copyright 2023 上海旺翔数字科技股份有限公司.  All rights reserved',
				text1:'广告合作',
				text2:'关于我们',
				text3:'联系我们',
				text4:'关注我们'
			}
		},
		eng:{
			header:{
				headerText1:'ADVANTAGE',
				headerText2:'MODEL',
				headerText3:'ABOUT HOTMOBI',
				headerText4:'COOPERATION',
				headerText5:'PARTNERS',
				headerText6:'CONTACT US',
				headerText7:'LOGIN',
				headerText7Obj:{
					text1:'ADVERTISERS',
					text2:'CHANNEL'
				},
				headerText8:'REGISTER',
				headerText8Obj:{
					text1:'ADVERTISERS',
					text2:'CHANNEL'
				},
				headerText9Obj:{
					text1:'English',
					text2:'Chinese'
				},
			},
			page_1:{
				bannerText:'WE LET',
				bannerList:['ADVERTISING MORE EFFECTIVE','ADVERTISING MORE CONTENT','ADVERTISING MORE MEANINGFUL'],
			},
			page_2:{
				title:'THE MOBILE MARKETING PLATFORM',
				desc:'Massive overseas traffic resource aggregation ability, aggregated 30 platforms, 1.2 billion users, 22000 advertisers, 250 million active users, covering 100 countries and regions',
				text1:'Reliable advanced parameters (geos, os, device) designed reach your specific target audience to help acheive your business goals.',
				text2:'Enhanced self serve platform to meet all your tracking needs, independently verify your conversions and campaign results in real time',
				text3:'Highly diversified ad formats including banner, video, text etc all aimed at helping your campaign reach all mark- et segments',
				text4:'Retargeting generates greater sales by keeping your brand visible and bringing high value users back to your site when they are ready to buy.',
				text5:'Follow your campaigns in real-time and set precise user capping and daily budgets',
				text6:'Our state of the art ad serving platform automatically filters and rejects bot traffic,click farms,malwares and viruses.Additionally.',
			},
			page_3:{
				obj1:{
					title:'DATA DOCKING',
					model:'',
					desc:'Data docking: talking data platform, hot cloud, and their peers, and the server data back, digital effects for benign docking, guarantee on the effect of tracking and optimization'
				},
				obj2:{
					title:'THE METHOD OF PAYMENT',
					model:'',
					desc:'There are two modes: 1. Programmatic advertising with CPC/CPM real-time bidding. 2. Through CPA, it is possible to achieve good payment methods for registration settlement, activation settlement, and other deep-level settlements.'
				},
			},
			page33:{
				title:'ABOUT HOTMOBI',
				desc:'Hotmobi effect as the mobile marketing platform, is committed to using mobile advertising effectively change the way we communicate with end users, through the alliance network, video network, native network, application, network, network, social networking, stylized different channels, such as mobile search engine marketing strategy implementation on the earnings and platform characteristics.'
			},
			Nopage:{
				text1:"With global diversification and lar- ge scale channels, Hotmobi can help developers obtain large-scale overseas users, so that the products can quickly occupy the overseas target market.<br/><br/>On the other hand, based on Hotmobi's years of resources and technical accumulation in China, we help overseas brands expand into the Chinese market through the use of DSP and ADX strategies.",
				text2:"Advanced intelligent scene matching engine technology, segmented user life, work, and entertainment scenarios, accurately matched advertisers' advertisements, and personalized marketing.",
				text3:"China's big data computing engine technology, processing huge data advertising demand, and establishing the model, precision delivery.",
				text4:'Massive overseas traffic resource aggregation ability, aggregated 30 platforms, 1.2 billion users, 22000 advertisers, 250 million daily lives, covering 100 countries and regions',
				text5:'The depth of the advertising SDK customization engine technology, through the underlying big data operation, matches the advertisement form suitable for the advertiser, the flexible switch advertisement form, enhances the advertisement marketing effect.',
			},
			adlogoin:{
				title:'Greater profits',
				descObj1:{
					text1:'Avoid invalid clicks and increase ROI',
					text2:'Hotmobi innovative self-service platform to make more the use of traffic to ensure superior advertising effectiveness.'
				},
				descObj2:{
					text1:'Do you have high-quality traffic?',
					text2:'Join us, get mero good quality monetize traffic'
				},
				btn1:"AD REGISTERED",
				btn2:'MEDIA REGISTRATION'
			},
			page_4:{
				title:'PARTNERS'
			},
			page_5:{
				title:'CONTACT US',
				type1:'MEDIA RESOURCES',
				type2:'ADVERTISING',
				form1:'The name',
				form2:'The name of the company',
				form3:'The phone',
				form4:'Email',
				form5:'subject',
				form6:'Input content...',
				btn:'SEND'
			},
			footer:{
				copy:'© Copyright 2023 Shanghai Wang Xiang Digital Technology Co., Ltd. All rights reserved. ',
				text1:'AD cooperation',
				text2:'About us',
				text3:'Contact us',
				text4:'Pay attention to our'
			}
		}
	  },
      state:false,
      zcNum:1,
      px:0,
      contentNum:1,
      languageState:false,
      from:{
        name:'',
        company:'',
        phone:'',
        email:'',
        subject:'',
        message:'',
        languageSend: 1,
        switchs: 1,
        paltform: 1,
        callback: 'success_jsonpCallback'
      }
    };
  },
  // components: { Banner,callPhone,custom },
  watch: {
    $route: {
      handler: function(val, oldVal){
		if(val.query.l == 'Chinese'){
			this.htmlMark = 'cn'
		}else{
			this.htmlMark = 'eng'
			document.title = 'Hotmobi-THE MOBILE MARKETING PLATFORM'
		}
      },
      // 深度观察监听
      deep: true,
      immediate:true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    setInterval(() => {
      if( this.px == -4830){
        this.px = 0
      }
      this.px = this.px - 1
    }, 20);
  },
  methods: {
	tabsColor(iddom) {
		// 获取对应iddom
		const tabsId = document.querySelector(`${iddom}`);
		// 判断进行平滑滚动
		if (tabsId) {
		window.scrollTo({
			top: tabsId.offsetTop,
			behavior: "smooth",
		});
		}
	},
    btnSend(){
      if (this.from.name == '') {
		this.$notify.error({
          title: `${this.htmlMark == 'eng' ? 'ERROR' :'错误'}`,
          message: `${this.htmlMark == 'eng' ? 'Name cannot be blank.' :'请输入姓名！'}`,
          position: 'bottom-right'
        });
        return false;
      }
      if (this.from.company == '') {
		this.$notify.error({
          title: `${this.htmlMark == 'eng' ? 'ERROR' :'错误'}`,
          message: `${this.htmlMark == 'eng' ? 'company cannot be blank.' :'请输入公司名称！'}`,
          position: 'bottom-right'
        });
        return false;
      }
      let str = /^1[3456789]\d{9}$/;
      if (!str.test(this.from.phone)) {
		this.$notify.error({
          title: `${this.htmlMark == 'eng' ? 'ERROR' :'错误'}`,
          message: `${this.htmlMark == 'eng' ? 'Phone number cannot be blank.' :'电话必填！'}`,
          position: 'bottom-right'
        });
        return false;
      }
      var reg = /^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/;
      if (!reg.test(this.from.email)) {
		this.$notify.error({
          title: `${this.htmlMark == 'eng' ? 'ERROR' :'错误'}`,
          message: `${this.htmlMark == 'eng' ? 'Email cannot be blank.' :'请输入邮箱！'}`,
          position: 'bottom-right'
        });
        return false;
      }
    //   console.log(this.from)
    },
	changeLanguage(text){
		this.$router.push(`${this.$route.path}?l=${text}`)
	},
    selectLang(){
      this.languageState = this.languageState ? false :true
    },
    toContentNum(num){
      this.contentNum = num;
      this.from.switchs = num
    },
    zc(num){
      this.zcNum = num
    },
    handleScroll(e) {
      // 处理滚动事件的逻辑
      if(window.scrollY > 400){
        this.state = true
      }else{
        this.state = false
      }
    },
    toOther(path){
      window.open(path);
    },
    toPath(path,target){
      if(!path){
        return
      }
      if(path.includes(this.OUT_URL)){
        window.open(path, '_blank');
      }else{
        this.$router.push({path:path,query: {}})
      }
    }
  },
};
</script>
<style lang='less' src="./index.less"  >
</style>